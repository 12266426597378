import Repository from './repository'

const resource = '/recebcaixa/pix'

export default {
    qrCodeGerado (data) {
        return Repository.get(`${resource}/qrcodegerado`, { params: data })
    },
    qrCodeCancelado (data) {
        return Repository.get(`${resource}/qrcancelado`, { params: data })
    },
    qrCodePagamento (data) {
        return Repository.get(`${resource}/statuspagamento`, { params: data })
    },
    baixarDuplicataRota (idPix) {
        return Repository.post(`${resource}/baixarduplicatarota?cdpix=${idPix}`)
    },
}
