<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card
        style="height: 100%; width: 100%;"
        class="d-flex justify-center"
      >
        <!-- <v-img
          v-if="imgQr !== null"
          :lazy-src="imgQr"
          max-height="300"
          :src="getUrl(img)"
          contain
          class="rounded-lg"
        /> -->
        <div
          v-if="imgQr !== null"
          class="mt-15"
        >
          <div class="d-flex flex-column align-center">
            <h1 style="font-size:50px;">
              <span class="blue--text text-darken-4">{{ cliente }}</span>
            </h1>
            <h1
              class="pb-10 pt-5"
              style="font-size:50px;"
            >
              <span class="blue--text text-darken-4">R$</span> {{ formatPrice(valor) }}
            </h1>
            <qrcode-vue
              :value="imgQr"
              size="300"
              level="H"
            />
          </div>
          <v-row class="pt-5 pl-3">
            <span
              v-if="showMensagemErro"
              style="color: red; font-size: 20px;"
            >{{ mensagemErro }}</span>
          </v-row>
          <v-row
            v-if="flCarregaZap"
          >
            <v-col>
              <v-text-field
                v-model="telefone"
                v-mask="'(##)#####-####'"
                label="WhatsApp"
                hide-details
                outlined
                dense
                :counter="14"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="flCarregaZap"
            class="d-flex justify-center"
          >
            <v-btn
              depressed
              color="primary"
              @click="onEnviarZapCliente"
            >
              Enviar Pix Copia e Cola
            </v-btn>
          </v-row>
        </div>
        <div
          v-else
          class="d-flex align-center"
        >
          <h2>Aguardando o proximo QR-CODE</h2>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import recebCaixaRep from '../../../components/services/recebCaixaRepository'
  import QrcodeVue from 'qrcode.vue'
  export default {
    components: {
      QrcodeVue,
    },
    props: {
      idpix: {
        type: Number,
        default: 0,
        required: false,
      },
    },
    data () {
      return {
        title: 'AAAAAA',
        idIntevalQrCode: 0,
        idIntevalPagamento: 0,
        idIntevalPagamentoCancelado: 0,
        statusUtilizado: null,
        flCarregaZap: false,
        // 2022032809392407
        caixaData: {
          cdCaixa: 18,
          status: 'A',
        },
        dialog: true,
        imgQr: null,
        cliente: null,
        valor: null,
        idPix: null,
        showMensagemErro: false,
        mensagemErro: '',
        telefone: '',
      }
    },
    created () {
      this.loadTela()
    },
    mounted () {
      this.existeCaixa()
    },
    methods: {
      loadTela () {
        this.flCarregaZap = this.idpix !== 0
        this.timeIntervalConsultaQrCode()
      },
      existeCaixa () {
        // idpix - é um paramentro passado para consulta de rota
        if (this.idpix === 0) {
          if (this.$store.getters.caixa === null) {
            this.$swal({
              icon: 'warning',
              text: 'Não existe caixa associado ao seu usuário!',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) this.$router.replace('/v1')
            })
          }
        }
      },
      // TODA VEZ SE INICIA COM ESSA CONSULTA
      // QUANDO TEM QRCODE GERA EM TELA E CHAMA O TIME timeIntervalConsultaPagamento, PARA SABER SE FOI PAGO
      async consultaQrCode () {
        try {
          // PAUSA A CONSULTA CASO NÃO TENHA DADOS VOLTA A CONSULTA NOVAMENTE
          // SE TIVER DADOS FICA CONSULTANDO O PAGAMENTO SE ESTA COM STAUS B - LIQUIDADO
          // console.log(this.$store.getters.caixa)
          this.caixaData = {
            cdCaixa: this.idpix === 0 ? this.$store.getters.caixa === null ? 0 : this.$store.getters.caixa.cdCaixa : 0,
            status: 'A',
            stutilizado: this.idpix === 0 ? 'N' : 'R',
            idpix: this.idpix,
          }
          this.cleantimeIntervalConsultaQrCode()
          await recebCaixaRep.qrCodeGerado(this.caixaData).then((resp) => {
            if (resp.data.result === null) this.timeIntervalConsultaQrCode()
            else {
              this.cleantimeIntervalConsultaQrCode()
              this.imgQr = resp.data.result.emv
              this.cliente = resp.data.result.nmCliente
              this.valor = resp.data.result.vlPix
              this.cdpix = resp.data.result.cdPix
              this.timeIntervalConsultaPagamento()
              this.timeIntervalConsultaCancelado()
              // console.log('timeIntervalConsultaPagamento')
            }
          }).catch(() => {
            this.timeIntervalConsultaQrCode()
          })
        } catch (error) {
          this.timeIntervalConsultaQrCode()
          if (String(error).includes('500')) {
            this.$swal({
              icon: 'warning',
              text: error,
            })
          }
        }
      },
      async consultaQrCodePagamento () {
        try {
          // console.log('consultaQrCodePagamento->')
          this.cleantimeIntervalConsultaPagamento()
          this.dadosConsulta = {
            cdPix: this.cdpix,
          }
          await recebCaixaRep.qrCodePagamento(this.dadosConsulta).then((resp) => {
            // console.log('consultaQrCodePagamento->THEN')
            if (resp.data !== null) {
              // console.log('consultaQrCodePagamento->IF')
              this.cleantimeIntervalConsultaPagamento()
              this.cleantimeIntervalConsultaPagamentoCancelado()
              this.timeIntervalConsultaQrCode()
              this.imgQr = null
              this.cliente = null
              this.valor = null
              this.idpix = 0
              this.showMensagemErro = false
              this.mensagemErro = ''
              this.statusUtilizado = resp.data.stUtilizado
              this.baixarDuplicataRota(this.cdpix)
            }
            // } else {
            //   console.log('consultaQrCodePagamento->else')
            // }
          })
        } catch (error) {
          // console.log('consultaQrCodePagamento->catch')
          this.cleantimeIntervalConsultaPagamento()
          this.timeIntervalConsultaPagamento()
          if (String(error).includes('500')) {
            this.$swal({
              icon: 'warning',
              text: error,
            })
          }
        }
        // if (this.statusUtilizado === 'R') {
        //   this.cleantimeIntervalConsultaPagamento()
        //   this.cleantimeIntervalConsultaQrCode()
        //   this.statusUtilizado = null
        //   this.baixarDuplicataRota(this.cdpix)
        // }
      },

      async baixarDuplicataRota (idPix) {
        try {
          await recebCaixaRep.baixarDuplicataRota(idPix).then((resp) => {
            this.statusUtilizado = null
            this.$emit('onSairRecebeCaixaRota')
          })
        } catch (error) {
          if (String(error).includes('500')) {
            this.$swal({
              icon: 'warning',
              text: error,
            })
          }
        }
      },
      async consultaQrCodeCancelado () {
        try {
          // PAUSA A CONSULTA CASO NÃO TENHA DADOS VOLTA A CONSULTA NOVAMENTE
          // SE TIVER DADOS FICA CONSULTANDO O PAGAMENTO SE ESTA COM STAUS B - LIQUIDADO
          this.caixaData.cdpix = this.cdpix
          // PARO O TIME PARA FAZER A CONSULTA NO BANCO
          this.cleantimeIntervalConsultaPagamentoCancelado()
          this.cleantimeIntervalConsultaPagamento()
          await recebCaixaRep.qrCodeCancelado(this.caixaData).then((resp) => {
            if (resp.data.result === null) this.timeIntervalConsultaCancelado()
            else {
              // CLEAN TIME INTERNVAL CONSULTA PAGAMENTO
              this.cleantimeIntervalConsultaPagamento()
              // CLEAN TIME INTERNVAL CONSULTA QR CODE
              this.cleantimeIntervalConsultaQrCode()
              // ATIVA CONSULTA QR CODE NOVA
              this.timeIntervalConsultaQrCode()
              this.imgQr = null
              this.cliente = null
              this.valor = null
              this.idpix = 0
              // console.log(this.$store.getters.caixa.cdCaixa)
              this.$emit('onSairRecebeCaixaRota')
            }
          })
        } catch (error) {
          this.timeIntervalConsultaPagamento()
          this.timeIntervalConsultaCancelado()
          if (String(error).includes('500')) {
            this.$swal({
              icon: 'warning',
              text: error,
            })
          }
        }
      },
      onEnviarZapCliente () {
        if (this.telefone.length === 14) {
          this.showMensagemErro = false
          var numsStr = this.telefone.replace(/[^0-9]/g, '')
          // window.location.href =
          window.open('https://wa.me/55' + numsStr + '?text=' + this.imgQr, '_blank')
        } else {
          this.showMensagemErro = true
          this.mensagemErro = 'Informe o número correto!'
          // this.$swal({
          //   icon: 'warning',
          //   text: 'Informe o número correto!',
          // })
        }
      },
      timeIntervalConsultaQrCode () {
        this.idInteval = setInterval(this.consultaQrCode, 2000)
      },
      cleantimeIntervalConsultaQrCode () {
        clearInterval(this.idInteval)
      },
      timeIntervalConsultaPagamento () {
        this.idIntevalPagamento = setInterval(this.consultaQrCodePagamento, 2000)
      },
      cleantimeIntervalConsultaPagamento () {
        clearInterval(this.idIntevalPagamento)
      },
      timeIntervalConsultaCancelado () {
        this.idIntevalPagamentoCancelado = setInterval(this.consultaQrCodeCancelado, 5000)
      },
      cleantimeIntervalConsultaPagamentoCancelado () {
        clearInterval(this.idIntevalPagamentoCancelado)
      },

      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
    },
  }
</script>
